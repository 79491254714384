
import React from "react"
import Layout from "../../components/Layout"
import ProtectedPage from '../../components/common/ProtectedPage'
import ChangePassword from '../../components/Account/Password'

const ChangePasswordPage = () => (
  <Layout seoProps={{title: "Change Your Password"}}>
    <ProtectedPage>
        <ChangePassword />
    </ProtectedPage>
  </Layout>
)

export default ChangePasswordPage
