import React, { useState, useContext, useEffect } from 'react'
import styled from 'styled-components'
import ErrorsContext from '../../../providers/ErrorsProvider'
import AuthUserContext from '../../../providers/AuthProvider'
import { PaddingLeftRightDesktop, BreakpointTablet, BreakpointMobile, BreakpointSmallMobile } from '../../Layout/GlobalStyle'
import { SettingsContainer, settingsStyles } from '../index'
import TextField from '@material-ui/core/TextField';
import { updateUser, updateUsersFirstLastName, updateUserPassword } from '../../../services/user.services'
import { Link } from 'gatsby'
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { ACCOUNT } from '../../Layout/routes'
import Button from '@material-ui/core/Button';


export const SubSettingsPageContainer = styled.div`
    display: flex;
    justify-content: center;
    padding-top: 20px;
    min-height: 100vh;
    width: 100%;
    max-width: 1000px;

    .page-title{
        font-weight: 400;
        font-size: 28px;
        line-height: 1.2;
        font-family: var(--Font2);
    }

    
    .sub-page-description{
        font-size: small;
        color: #6A6A6A;
    }

    @media only screen  and (max-width: ${BreakpointTablet + 'px'}){
        .page-title{
            font-size: x-large;
        }
    }
  
  

    .account-back-button{
        color: black;
    }

    .sub-page-description{
        font-size: medium;
        font-family: var(--Font2);
    }

    .account-back-button{
        width: 75px;
        display: flex;
        align-items: center;
        height: 40px;
        font-size: medium;
        background: transparent;
        
        justify-content: space-between;
        border: 0px solid transparent;
        text-transform: uppercase;

        :hover{
            cursor:pointer;
            text-decoration: underline;
        }

    }

    .account-save-button{
        background: var(--PrimaryColor);
        width: 100%;
        height: 40px;
        border: 1px solid transparent;
        font-size: large;
        text-transform: uppercase;
        color: white;
        border-radius: 4px;
    }

    

`

export const SubSettingsWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;



    .done-wrapper{
        margin-top: 14px;
        padding: 6px 10px 6px 10px;
        button{
            height: 29px;
            width: 52px;
            background: var(--callToActionBtnGradient1);
            color: var(--callToActionBtnFontColor1);
            border-color: var(--callToActionBtnBorderColor1);
            border-width: 1px;
            border-radius: var(--callToActionBtnBorderRadius1);
            font-size: var(--callToActionBtnFontSize1);
            font-weight: var(--callToActionBtnFontWeight1);
        }
    }
`

export const SubSettingsContent = styled.div`
    padding: 14px 18px 14px 18px;
    border: 1px solid rgb(221, 221, 221);
    border-radius: 4px;
    background: white;
    margin-top: 20px;
    
    .sub-page-description{
        line-height: 19px;
    }
    .form-area{
        margin-top: 40px;
        margin-bottom: 14px;
        display: grid;
        max-width: 300px;
        grid-template-columns: 1fr;
        grid-gap: 20px;
    }
`
function PasswordPage(props) {
    const { setErrors, errors, clearErrors } = useContext(ErrorsContext)
    const { user, setUser } = useContext(AuthUserContext)
    const [loading, setLoading] = useState(false)


    const [confirm_password, setConfirmPassword] = useState("");
    const [new_password, setNewPassword] = useState("");
    const [old_password, setOldPassword] = useState("")

    const [last_name, setLastName] = useState("");
    const classes = settingsStyles()

    


    const onSubmit = () => {
        setLoading(true)
        updateUserPassword({ confirm_password, new_password, old_password })
            .then(decoded => {
                setLoading(false)
                setUser(decoded)
                setErrors({})
                alert("Success")
            })
            .catch(err => {
                setErrors(err.response.data)
                setLoading(false)
            })
    }

  

    return (
        <SettingsContainer>

            <SubSettingsPageContainer>
                <SubSettingsWrapper>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link color="inherit" to={ACCOUNT} >
                            Account
                        </Link>

                        <Typography color="textPrimary">Change Password</Typography>
                    </Breadcrumbs>

                    <SubSettingsContent>
                        <h1 className="page-title">Change your password</h1>

                        <p className="sub-page-description">
                            If you want to change the password associated with your FindAnHerb account, you may do so below. <br />
                        Be sure to click the <b>Save Changes</b> button when you are done.
                        </p>
                        <div className="form-area">


                            <TextField type="password" size="small" value={old_password} onChange={(e) => setOldPassword(e.target.value)} helperText={errors.old_password ? errors.old_password : ""} error={errors.old_password ? true : false} id="oldPasswordFormInput" label="Current Password" variant="outlined" />
                            <TextField type="password" size="small" value={new_password} onChange={(e) => setNewPassword(e.target.value)} helperText={errors.new_password ? errors.new_password : ""} error={errors.new_password ? true : false} id="newPasswordFormInput" label="New Password" variant="outlined" />
                            <TextField type="password"  size="small" value={confirm_password} onChange={(e) => setConfirmPassword(e.target.value)} helperText={errors.confirm_password ? errors.confirm_password : ""} error={errors.confirm_password ? true : false} id="confirmPasswordFormInput" label="Confirm Password" variant="outlined" />


                        </div>
                        <br/>
                        <Button disabled={loading} disableElevation variant="contained" color="secondary"  className={classes.saveButton} onClick={() => onSubmit()}>Sav{loading ? 'ing' : 'e'} Changes</Button>

                    </SubSettingsContent>
                </SubSettingsWrapper>
            </SubSettingsPageContainer>
        </SettingsContainer>

    )
}

export default PasswordPage







